<template>
  <div v-if="editable" class="d-flex align-center">
    <v-menu
      ref="startDateMenu"
      v-model="showStartDateMenu"
      :close-on-content-click="false"
      :return-value.sync="startDate"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <!-- Input -->
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="startDate"
          :error-messages="startDateError"
          prepend-inner-icon="mdi-calendar"
          placeholder="시작일"
          label="시작일"
          solo
          flat
          dense
          clearable
          readonly
          outlined
          hide-details
          v-bind="attrs"
          v-on="on"
          @input="v$.startDate.$touch()"
          @blur="v$.startDate.$touch()"
        ></v-text-field>
      </template>
      <!-- Date Picker-->
      <v-date-picker
        v-model="startDate"
        no-title
        scrollable
        :day-format="formatDay"
        @input="picked => $refs.startDateMenu.save(picked)"
      />
    </v-menu>
    <span class="px-2">~</span>
    <v-menu
      ref="endDateMenu"
      v-model="showEndDateMenu"
      :close-on-content-click="false"
      :return-value.sync="endDate"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <!-- Input -->
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="endDate"
          :error-messages="endDateError"
          prepend-inner-icon="mdi-calendar"
          placeholder="종료일"
          label="종료일"
          solo
          flat
          dense
          clearable
          readonly
          outlined
          hide-details
          v-bind="attrs"
          v-on="on"
          @input="v$.endDate.$touch()"
          @blur="v$.endDate.$touch()"
        ></v-text-field>
      </template>
      <!-- Date Picker-->
      <v-date-picker
        v-model="endDate"
        no-title
        scrollable
        :day-format="formatDay"
        @input="picked => $refs.endDateMenu.save(picked)"
      />
    </v-menu>
  </div>
  <div v-else class="d-flex align-center">
    <v-icon small class="mr-1">mdi-calendar</v-icon>
    <span>{{ `${startDate} ~ ${endDate}` }}</span>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

export default {
  name: "DateRangeFieldItem",
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  data: () => ({
    showStartDateMenu: false,
    showEndDateMenu: false
  }),
  computed: {
    startDate: {
      get() {
        return this.value?.startDate ?? "";
      },
      set(val) {
        this.$emit("input", { startDate: val, endDate: this.endDate });
      }
    },
    endDate: {
      get() {
        return this.value?.endDate ?? "";
      },
      set(val) {
        this.$emit("input", { startDate: this.startDate, endDate: val });
      }
    },
    startDateError() {
      const { $dirty, required, beforeThanEndDate } = this.v$.startDate;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      if (beforeThanEndDate.$invalid) {
        errors.push("종료일 보다 이후로 지정할 수 없습니다.");
      }
      return errors;
    },
    endDateError() {
      const { $dirty, required, afterThanStartDate } = this.v$.endDate;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      if (afterThanStartDate.$invalid) {
        errors.push("시작일 보다 이전으로 지정할 수 없습니다.");
      }
      return errors;
    }
  },
  methods: {
    // 달력 '일' 표시 포맷팅
    formatDay(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    },
    async validate() {
      return await this.v$.$validate();
    }
  },

  validations() {
    return {
      startDate: {
        required: requiredIf(this.templateFrame?.required),
        beforeThanEndDate: () => {
          if (!this.startDate || !this.endDate) return true;
          const startDate = new Date(this.startDate);
          const endDate = new Date(this.endDate);
          return startDate <= endDate;
        }
      },
      endDate: {
        required: requiredIf(this.templateFrame?.required),
        afterThanStartDate: () => {
          if (!this.startDate || !this.endDate) return true;
          const startDate = new Date(this.startDate);
          const endDate = new Date(this.endDate);
          return startDate <= endDate;
        }
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 기본값 초기화 (수정일 경우 기존값 할당)
      this.startDate = this.value?.startDate ?? "";
      this.endDate = this.value?.endDate ?? "";
    });
  }
};
</script>

<style lang="scss" scoped></style>
